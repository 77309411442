import React from "react";
import styled from "styled-components";
import { components } from "react-select";

const Label = styled.label `
  left: 10px;
  padding: 0 5px;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  color: #1e4c82;
  font-size: ${(props) => (props.$isfloating ? `16px` : `18px`)};
`;

// Floating label selectbox
export const Control = (props) => {
    return (
        <>
            <Label $isfloating={props.isFocused || props.hasValue}>{props.selectProps.placeholder}</Label>
            <components.Control {...props} />
        </>
    );
};