import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { setAlert, setIsLoading } from '../../store/ui/uiSlice';
import './RequestDocsForWarehouse.scss';
function RequestDocsForWarehouse(props) {
    const [complainData, setComplainData] = useState({});
    const [video, setVideo] = useState();
    const [remarks, setRemarks] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsLoading(true));
        const searchParams = new URLSearchParams(props.location.search);
        if (searchParams.get('complainId') && searchParams.get('orderId')
            && searchParams.get('awb')) {
            let reqBody = {
                "id": searchParams.get('complainId'),
            };
            let config = {
                method: 'POST',
                url: process.env.REACT_APP_JAVA_API_URL + 'api/support/fetchComplainDetailsForThirdParty',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: reqBody
            };

            Axios(config)
                .then(res => {
                    if (res.data.code == 1 && !res.data.data.increffStatus) {
                        dispatch(setIsLoading(false));
                        let newComplaintData = {
                            "product_name": res.data.data.productName,
                            "complainId": searchParams.get('complainId'),
                            "orderId": searchParams.get('orderId'),
                            "awb": searchParams.get('awb')
                        }
                        setComplainData(newComplaintData);
                    } else {
                        dispatch(setIsLoading(false));
                        setComplainData({});
                        Swal.fire({
                            title: 'Oops!',
                            text: res.data.message === 'Success' ? 'You have already submitted the proof.' : res.data.message,
                            icon: 'error',
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false
                        });
                        return;
                    }
                }).catch(error => {
                    console.log(error);
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                })
            setComplainData(complainData);
            dispatch(setIsLoading(false));
        } else {
            window.location.replace('/')
        }
    }, []);
    const removeItem = (file, type) => {
        if (type === 'video') {
            setVideo("");
        }
    }
    const submitForm = () => {
        if (!video) {
            dispatch(setAlert({ status: true, msg: 'Please upload a video atleast.', variant: 'danger' }));
            setTimeout(() => {
                dispatch(setAlert({ status: false, msg: '', variant: 'danger' }));
            }, 2500);
            return;
        }
        dispatch(setIsLoading(true));
        let reqBody = new FormData();
        if (video) {
            reqBody.append('video', video[0]);
        }
        if (remarks) {
            reqBody.append('comments', remarks);
        }
        reqBody.append('complainId', complainData.complainId);
        let config = {
            method: 'POST',
            url: process.env.REACT_APP_JAVA_API_URL + 'api/support/saveIncreffProofOfPacking',
            headers: {
                'Content-Type': 'application/json'
            },
            data: reqBody
        };
        document.body.scrollTo(0, 0);
        Axios(config)
            .then(res => {
                dispatch(setIsLoading(false));
                if (res.data.code == 1) {
                    Swal.fire({
                        title: 'Success',
                        text: 'You have successfully submitted the documents.',
                        icon: 'success',
                        showConfirmButton: false,
                        backdrop: true,
                        allowOutsideClick: false
                    });
                    setIsSubmitted(true);
                    return;
                } else if (res.data.code == 2) {
                    Swal.fire({
                        title: 'Error',
                        text: 'You have already submitted the documents.',
                        icon: 'error',
                        showConfirmButton: false,
                        backdrop: true,
                        allowOutsideClick: false
                    });
                    setIsSubmitted(true);
                    return;
                } else {
                    dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                    setIsSubmitted(false);
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                }
            }).catch(error => {
                setIsSubmitted(false);
                dispatch(setIsLoading(false));
                dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                setTimeout(() => {
                    dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                }, 3000);
            })
    }
    return (
        <div id="request-docs-wrapper" className={isSubmitted ? 'd-none' : ''}>
            {
                complainData && Object.keys(complainData).length ?
                    <div className='container text-left'>
                        <div className='mt-4'>
                            <div className='details'>
                                <p style={{ flex: '1' }}>
                                    <span className='font-weight-bold'>Waybill &nbsp;</span>
                                    <span style={{ paddingLeft: '3.2rem' }} className='font-weight-bold'> : &nbsp;</span>
                                    <span >{complainData?.awb || '-'}</span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p style={{ flex: '1' }}>
                                <span className='font-weight-bold'>Order ID &nbsp;</span>
                                <span style={{ paddingLeft: '2.5rem' }} className='font-weight-bold'> : &nbsp;</span>
                                <span>{complainData?.orderId}</span>
                            </p>
                        </div>
                        <div>
                            <p style={{ flex: '1' }}>
                                <span className='font-weight-bold'>Product Name&nbsp;</span>
                                <span style={{ paddingLeft: '0rem' }} className='font-weight-bold'> : &nbsp;</span>
                                <span>{complainData?.product_name}</span>
                            </p>
                        </div>
                        <h5>Please upload your files below: </h5>
                        <div>
                            <textarea name='user-remarks'
                                placeholder='Enter your remarks here'
                                onChange={(event) => {
                                    setRemarks(event.target.value);
                                }}
                            />
                        </div>
                        <section>
                            <div>
                                <input
                                    type={'file'}
                                    name="video-upload"
                                    id="video-upload"
                                    accept='video/*'
                                    onInput={(event) => {
                                        setVideo(event.target.files);
                                    }}
                                />
                                <label htmlFor='video-upload'>Upload Video*</label>
                            </div>
                            {
                                video ? <div className='file-box-wrapper border-0' style={{ width: '120px' }}>
                                    {
                                        <span className='file-box' key={video[0].name}>
                                            {video[0].name.slice(0, 10)}
                                            <button type='button' onClick={() => removeItem(video[0].name, 'video')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" /><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" /></svg>
                                            </button>
                                        </span>
                                    }
                                </div> : null
                            }
                        </section>
                        <button type='button'
                            className='btn btn-brand w-50'
                            onClick={submitForm}>Submit</button>
                    </div> : null
            }
        </div>
    );
}
export default RequestDocsForWarehouse;
