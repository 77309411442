import axios from 'axios';

export function trackOrder(order_no, identifier) {
  try {
    let data = {
      "awb":"",
      "orderId": order_no,
      "mobile": identifier
    };

    let config = {
      method: 'POST',
      url: process.env.REACT_APP_BUILD_API_URL + 'trackOrder',
      data: data
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

  } catch (e) {
    console.log("error " + e);
  }
}
