import Axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert, setIsLoading } from '../../store/ui/uiSlice';
import './Complain.scss';
import CForm1 from './CForm1';
import Form2 from './Form2';
import CForm3 from './CForm3';
import CThankYou from './CThankYou';
import Swal from 'sweetalert2';
import { subDeliveryOptions } from '../../helper/data';

function ConsoleComplain(props) {
    const [activeSteps, setActiveStep] = useState({ current: 1, completed: [] }); //TODO current has to be 1
    const [form1Details, setForm1Details] = useState({
        name: '',
        phoneNum: '',
        email: '',
        type: '',
        gender: '',
        age: ''
    });
    const [form2Details, setForm2Details] = useState({
        otp: ''
    });
    const [form3Details, setForm3Details] = useState({
        type: '',
        address: {
            address: '',
            pincode: '',
            city: '',
            state: '',
            landmark: ''
        },
        deliveryAddress: {
            address: '',
            pincode: '',
            city: '',
            state: '',
            landmark: ''
        },
        channel: ''
    });
    const [form1Errors, setForm1Errors] = useState({
        name: false,
        phoneNum: false,
        email: false,
        age: false,
        gender: false
    });
    const [form2Errors, setForm2Errors] = useState({
        otp: false
    });
    const [complainId, setComplanId] = useState('');
    const subOptionRef = useRef("");
    const [bearer, setBearer] = useState("");

    const dispatch = useDispatch();

    const getForm = () => {
        switch (activeSteps.current) {
            case 1:
                return <CForm1
                    form1Details={form1Details}
                    setForm1DetailsHandler={setForm1Details}
                    submitForm1Handler={submitForm1}
                    form1Errors={form1Errors}
                    subOptionRef={subOptionRef}
                />;
            case 2:
                return <Form2
                    form2Details={form2Details}
                    setForm2DetailsHandler={setForm2Details}
                    submitForm2Handler={submitForm2}
                    form1Details={form1Details}
                    form2Errors={form2Errors}
                    setActiveStepHandler={setActiveStep}
                />;
            case 3:
                return <CForm3
                    form3Details={form3Details}
                    form1Details={form1Details}
                    setForm3DetailsHandler={setForm3Details}
                    setForm1DetailsHandler={setForm1Details}
                    submitForm3Handler={(cb) => submitForm3(cb)}
                    bearer={bearer}
                />;
            case 4:
                return <CThankYou />;
            default:
                return null;
        }
    }
    const submitForm1 = () => {
        if (form1Details ) {
            const hasErrors = { name: false, email: false, phoneNum: false, type: false, type2: false, age: false, gender: false };
            // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // if (!re.test(form1Details.email)) {
            //     hasErrors.email = true;
            // } else hasErrors.email = false;

            if(!form1Details?.name) hasErrors.name = true;
            else hasErrors.name = false;

            if (form1Details.phoneNum.length != 10 || ![6, 7, 8, 9].includes(parseInt(form1Details.phoneNum.toString()[0]))) {
                hasErrors.phoneNum = true;
            } else hasErrors.phoneNum = false;

            if (!form1Details.type) hasErrors.type = true;
            else hasErrors.type = false;

            if (!form1Details.age || form1Details.age < 10 || form1Details.age > 90) {
                hasErrors.age = true;
            } else {
                hasErrors.age = false;
            }
            if (!form1Details.gender) {
                hasErrors.gender = true;
            } else {
                hasErrors.gender = false;
            }

            setForm1Errors(hasErrors);

            if (!Object.values(hasErrors).filter(Boolean).length) {
                // TODO call an api to send data and get otp
                dispatch(setIsLoading(true));
                setTimeout(()=>{
                    dispatch(setIsLoading(false));
                    setForm3Details(prev => ({ ...prev, type: subOptionRef?.current?.state?.selectValue[0] }))
                    const newSteps = { current: 3, completed: [1, 2] }
                    setActiveStep(newSteps);
                    document.body.scrollTo({ top: 0 });
                }, 1000);
                // let reqBody = {
                //     "name": form1Details.name,
                //     "mobile": form1Details.phoneNum, //only mandatory field
                //     "email": form1Details.email
                // };
                // let config = {
                //     method: 'POST',
                //     url: process.env.REACT_APP_BUILD_API_URL + 'sendComplainLogin',
                //     headers: {
                //         'Content-Type': 'application/json'
                //     },
                //     data: reqBody
                // };
                // Axios(config)
                //     .then(res => {
                //         if (res.data.code) {
                //             setForm3Details(prev => ({ ...prev, type: subOptionRef?.current?.state?.selectValue[0] }))
                //             dispatch(setIsLoading(false));
                //             // dispatch(setAlert({ status: true, msg: "OTP has been successfully sent to your number.", variant: "success" }));
                //             // setTimeout(() => {
                //             //     dispatch(setAlert({ status: false, variant: "success", msg: "" }));
                //             // }, 3000);
                //             const newSteps = { current: 3, completed: [1, 2] }
                //             setActiveStep(newSteps);
                //             document.body.scrollTo({ top: 0 });
                //         } else {
                //             dispatch(setIsLoading(false));
                //             dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                //             setTimeout(() => {
                //                 dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                //             }, 3000);
                //         }
                //     }).catch(error => {
                //         dispatch(setIsLoading(false));
                //         dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                //         setTimeout(() => {
                //             dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                //         }, 3000);
                //     })
            }
        } else {
            dispatch(setAlert({ status: false, variant: "danger", msg: "Please fill in required fields" }));
        }
    }

    const submitForm2 = (event) => {
        event.preventDefault();
        //TODO call API to verify otp
        if (form2Details && form2Details.otp && form2Details.otp.length != 6) {
            setForm2Errors({ otp: true });
        } else if (form2Details && form2Details.otp && form2Details.otp.length == 6) {
            setForm2Errors({ otp: false });
            dispatch(setIsLoading(true));
            let config = {
                method: 'POST',
                url: process.env.REACT_APP_BUILD_API_URL + 'verifyComplainOTP',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "mobile": form1Details.phoneNum,
                    "otp": form2Details.otp
                }
            };
            Axios(config)
                .then(res => {
                    if (res.data.code === 1) {
                        dispatch(setIsLoading(false));
                        setForm1Details(prev => ({ ...prev, token: res.data.token }))
                        const newSteps = { current: 3, completed: [1, 2] }
                        setActiveStep(newSteps);
                    } else {
                        dispatch(setIsLoading(false));
                        dispatch(setAlert({ status: true, msg: res.data.message, variant: "danger" }));
                        setTimeout(() => {
                            dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                        }, 3000);
                    }
                }).catch(error => {
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                });

        }
    };

    const submitForm3 = (callback) => {
        //api to submit complain data
        dispatch(setIsLoading(true));
        let reqBody;
        switch (form1Details.type.value) {
            case 'items_missing':
            case 'poor_quality':
            case 'wrong_items':
                reqBody = {
                    complaint_type: form1Details.type?.value,
                    name: form1Details.name,
                    phone: form1Details.phoneNum,
                    age: form1Details.age,
                    gender: form1Details.gender.value,
                    channel: form3Details.channel?.value,
                    sku: form3Details.skus,
                    order_id: form3Details.orderId
                }
                if(form1Details.email) {
                    reqBody = {
                        ...reqBody, 
                        email: form1Details.email
                    }
                }
                if(form1Details.issue_description) {
                    reqBody = {
                        ...reqBody, 
                        issue_description: form3Details.comments
                    }
                }
                break;
            case 'product_quality':
                reqBody = {
                    complaint_type: form1Details.type.value,
                    name: form1Details.name,
                    phone: form1Details.phoneNum,
                    age: form1Details.age,
                    gender: form1Details.gender.value,
                    issue_description: form3Details.comments,
                    product_category: form3Details.category.label,
                    main_problem: form3Details.mainProblem?.value,
                    pickup_address: form3Details.address,
                    shipping_address: form3Details.deliveryAddress
                }
                if(form1Details.email) {
                    reqBody = {
                        ...reqBody, 
                        email: form1Details.email
                    }
                }
                break;
            case 'air_bike_installation':
                reqBody = {
                    complaint_type: form1Details.type.value,
                    name: form1Details.name,
                    phone: form1Details.phoneNum,
                    age: form1Details.age,
                    gender: form1Details.gender.value,
                    issue_description: form3Details.comments,
                    product_category: form3Details.category.label,
                    main_problem: form3Details.mainProblem?.value,
                    shipping_address: form3Details.deliveryAddress
                }
                if(form1Details.email) {
                    reqBody = {
                        ...reqBody, 
                        email: form1Details.email
                    }
                }
                break;
            case 'delivery':
            case 'orders_related':
            case 'others':
            case 'general_query':
                reqBody = {
                    complaint_type: form1Details.type.value,
                    name: form1Details.name,
                    phone: form1Details.phoneNum,
                    age: form1Details.age,
                    gender: form1Details.gender.value,
                    main_problem: form3Details.mainProblem?.value,
                    issue_description: form3Details.comments,
                }
                if(form1Details.email) {
                    reqBody = {
                        ...reqBody, 
                        email: form1Details.email
                    }
                }
                break;
            default:
                break;
        }
        let config = {
            method: 'POST',
            url: `${process.env.REACT_APP_BUILD_API_URL}/saveComplaintByAgent`,
            data: reqBody,
            headers: {
                'Authorization': `Bearer ${bearer}`
            }
        };
        Axios(config)
            .then(res => {
                if (res.data.code === 1) {
                    setComplanId(res.data.complainId);
                    const newSteps = { current: 4, completed: [1, 2, 3] }
                    setActiveStep(newSteps);
                    if (callback) callback();
                    dispatch(setIsLoading(false));
                    document.body.scrollTo({ top: 0 });
                } else if (res.data.code) {
                    if (callback) callback();
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: res.data.message, variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                } else {
                    if (callback) callback();
                    dispatch(setIsLoading(false));
                    if (res.data.message === "Token is invalid") {
                        dispatch(setAlert({ status: true, msg: "Something went wrong, try to refresh the page.", variant: "danger" }));
                        setTimeout(() => {
                            dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                        }, 3000);
                        return;
                    }
                    dispatch(setAlert({ status: true, msg: res.data.message, variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                }
            }).catch(error => {
                if (callback) callback();
                dispatch(setIsLoading(false));
                dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                setTimeout(() => {
                    dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                }, 3000);
            })
    }

    useEffect(() => {
        document.title = "Register Complaint: beatXP";
        const searchParams = new URLSearchParams(props.location.search);
        if (searchParams.get('email') && searchParams.get('token')) {
            try {
                let verifyUser = async () => {
                    let reqBody = {
                        'email': searchParams.get('email'),
                        'password': searchParams.get('token')
                    };
                    if(searchParams.get('token')) {
                        setBearer(searchParams.get('token'));
                    } else {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Oops! Something went wrong.',
                            icon: 'error',
                            backdrop: true,
                            allowOutsideClick: false,
                            showConfirmButton: false
                        });
                        return;
                    }
                }
                verifyUser();
            } catch (err) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Oops! Something went wrong.',
                    icon: 'error',
                    backdrop: true,
                    allowOutsideClick: false,
                    showConfirmButton: false
                });
                return;
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Oops! Something went wrong.',
                icon: 'error',
                backdrop: true,
                allowOutsideClick: false,
                showConfirmButton: false
            });
            return;
        }
    }, [])

    return (
        <div className='container mt-5'>
            <section id="header">
                <h3 className='mb-20'>Register your Complaint</h3>
                <p className='mb-20'>Please complete the form below with all the necessary details.
                    This will help us to better understand your needs and respond to you quickly with a solution.</p>
                <div className='progress-wrapper'>
                    <div className={`${activeSteps.completed.includes(1) ? 'step-completed' : ''} ${activeSteps.current == 1 ? 'active-step' : ''} progress-step `} >
                        <span className='step'>
                            {
                                activeSteps.completed.includes(1) ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.2408 2.64835L7.76409 13.545C7.18076 14.1517 6.24751 14.1517 5.66417 13.545L0.437501 8.06169C-0.145834 7.45506 -0.145834 6.4751 0.437501 5.86831C1.02083 5.26168 1.95409 5.26168 2.53742 5.86831L6.73743 10.255L16.1641 0.454969C16.7474 -0.151656 17.6807 -0.151656 18.264 0.454969C18.8241 1.0616 18.8241 2.04172 18.2408 2.64835Z" fill="white" />
                                </svg> : 1
                            }
                        </span>
                        <span>Personal Details</span>
                    </div>
                    <div className='line-bar' style={{ width: '100%', position: 'relative' }}>
                        <div className={`${activeSteps.completed.includes(1) ? 'line step-completed' : ''}`}>
                        </div>
                        <div className={`${activeSteps.completed.includes(2) ? 'line half-step-completed' : 'line'}`}>
                        </div>
                    </div>
                    <div className={`${activeSteps.completed.includes(2) ? 'step-completed' : ''} ${activeSteps.current == 2 ? 'active-step' : ''} progress-step `} >
                        <span className='step'>
                            {
                                activeSteps.completed.includes(2) ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.2408 2.64835L7.76409 13.545C7.18076 14.1517 6.24751 14.1517 5.66417 13.545L0.437501 8.06169C-0.145834 7.45506 -0.145834 6.4751 0.437501 5.86831C1.02083 5.26168 1.95409 5.26168 2.53742 5.86831L6.73743 10.255L16.1641 0.454969C16.7474 -0.151656 17.6807 -0.151656 18.264 0.454969C18.8241 1.0616 18.8241 2.04172 18.2408 2.64835Z" fill="white" />
                                </svg> : 2
                            }
                        </span>
                        <span>Verify OTP</span>
                    </div>
                    <div className='line-bar' style={{ width: '100%', position: 'relative' }}>
                        <div className={`${activeSteps.completed.includes(2) ? 'line step-completed' : ''}`}>
                        </div>
                        <div className={`${activeSteps.completed.includes(3) ? 'line half-step-completed' : 'line'}`}>
                        </div>
                    </div>

                    <div className={`${activeSteps.completed.includes(3) ? 'step-completed' : ""} ${activeSteps.current == 3 ? 'active-step' : ''} progress-step `} >
                        <span className='step'>{
                            activeSteps.completed.includes(3) ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.2408 2.64835L7.76409 13.545C7.18076 14.1517 6.24751 14.1517 5.66417 13.545L0.437501 8.06169C-0.145834 7.45506 -0.145834 6.4751 0.437501 5.86831C1.02083 5.26168 1.95409 5.26168 2.53742 5.86831L6.73743 10.255L16.1641 0.454969C16.7474 -0.151656 17.6807 -0.151656 18.264 0.454969C18.8241 1.0616 18.8241 2.04172 18.2408 2.64835Z" fill="white" />
                            </svg> : 3
                        }</span>
                        <span>Complaint Info</span>
                    </div>

                    <div className='line-bar' style={{ width: '100%', position: 'relative' }}>
                        <div className={`${activeSteps.completed.includes(3) ? 'line step-completed' : ''}`}>
                        </div>
                        <div className={`${activeSteps.current == 4 ? 'line half-step-completed' : 'line'}`}>
                        </div>
                    </div>

                    <div className={`${activeSteps.current == 4 ? 'step-completed' : ''} progress-step `} >
                        <span className='step'>{
                            activeSteps.current == 4 ? <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.2408 2.64835L7.76409 13.545C7.18076 14.1517 6.24751 14.1517 5.66417 13.545L0.437501 8.06169C-0.145834 7.45506 -0.145834 6.4751 0.437501 5.86831C1.02083 5.26168 1.95409 5.26168 2.53742 5.86831L6.73743 10.255L16.1641 0.454969C16.7474 -0.151656 17.6807 -0.151656 18.264 0.454969C18.8241 1.0616 18.8241 2.04172 18.2408 2.64835Z" fill="white" />
                            </svg> : 4
                        }</span>
                        <span>Register Complaint</span>
                    </div>
                </div>

            </section>
            {getForm()}
        </div>
    );
}

export default ConsoleComplain;