import React, { useEffect, useState } from 'react';

import './cssFooter.css'
function Footer(cat) {
    const [showFooter, setShowFooter] = useState(true);
    useEffect(() => {
        if (window.location.pathname.includes("warehouse")) {
            setShowFooter(false);
        } else if (window.location.search.includes('token') && window.location.search.includes('email')) {
            setShowFooter(false);
        } else {
            setShowFooter(true);
        }
    }, []);
    const category = cat['category'];

    return (
        <footer>
            {showFooter && <section className="main">
                <div className="social-media-wrapper mobileVisible">
                    <h3 className="m-0" >Follow Us</h3>
                    <div className="social-media-icons flex-center" >
                        <a rel="nofollow" target="_blank" href="https://www.instagram.com/getbeatxp/"><img loading="lazy" className="cursor-pointer" src="https://img.beatxp.com/prod/custom/instagram.svg" alt="instagram" /></a>
                        <a rel="nofollow" target="_blank" href="https://www.facebook.com/getbeatxp/"><img loading="lazy" className="cursor-pointer" src="https://img.beatxp.com/prod/custom/fb.svg" alt="facebook" /></a>
                        <a rel="nofollow" target="_blank" href="https://www.youtube.com/channel/UCxEQucVuFivdhCqoQw_SW-w"><img loading="lazy" className="cursor-pointer" src="https://img.beatxp.com/prod/custom/YouTube-Icon-White-Logo.wine.svg" alt="facebook" /></a>
                        <a rel="nofollow" target="_blank" href="https://www.linkedin.com/company/getbeatxp/"><img loading="lazy" className="cursor-pointer" src="https://img.beatxp.com/prod/custom/Linkedin.svg" alt="Linkedin" /></a>
                    </div>
                </div>
                <div className="d-flex justify-space-between content-wrapper div footer-content">
                    <div className="footer-content-wrapper">
                        <div>
                            <h3>Our Products</h3>
                            <ul>
                                {
                                    category.map(x => {
                                        return <li key={x.id}><a href={'https://www.beatxp.com/' + x.urlKey + '-' + x.id + '/'}>{x.name}</a></li>
                                    })
                                }
                            </ul>
                        </div>
                        <div>
                            <h3>Legal</h3>
                            <ul>
                                <li><a href={'https://www.beatxp.com/' + 'privacy-policy/'}>Privacy Policy</a></li>
                                <li><a href={'https://www.beatxp.com/' + 'refund-policy/'}>Refund Policy</a></li>
                                <li><a href={'https://www.beatxp.com/' + 'shipping-policy/'}>Shipping Policy</a></li>
                                <li><a href={'https://www.beatxp.com/' + 'terms-and-conditions/'}>Terms & Conditions</a></li>
                                {/* <li><a target="_blank" href="https://verify.beatxp.com">Protein Authenticity Check</a></li> */}
                            </ul>
                        </div>
                        <div>
                            <h3>Our Company</h3>
                            <ul>
                                <li ><a href={'https://www.beatxp.com/' + 'blog/'}>Blogs</a></li>
                                <li><a href={'https://www.beatxp.com/' + 'bulk-order-enquiry/'}>Bulk Enquiry</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3>Support</h3>
                            <ul>
                                <li >
                                    <a href={'https://support.beatxp.com/track-order'}>Track Your Order</a></li>
                                <li>
                                    <a href={'https://support.beatxp.com/track-complaint'}>Track Your Complaint</a></li>
                                <li >
                                    <a href={'https://support.beatxp.com/complain'}>Register Complaint</a></li>
                                <li ><a href={'https://www.beatxp.com/' + 'page/warranty/'}>Warranty Registration</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="desktopVisible">
                        <h3>Follow Us</h3>
                        <div className="social-media-icons">
                            <a target="_blank" rel="nofollow" href="https://www.instagram.com/getbeatxp/"><img loading="lazy" className="cursor-pointer" src="https://img.beatxp.com/prod/custom/instagram.svg" alt="instagram" /></a>
                            <a target="_blank" rel="nofollow" href="https://www.facebook.com/getbeatxp/"><img loading="lazy" className="cursor-pointer" src="https://img.beatxp.com/prod/custom/fb.svg" alt="facebook" /></a>
                            <a target="_blank" rel="nofollow" href="https://www.youtube.com/channel/UCxEQucVuFivdhCqoQw_SW-w"><img loading="lazy" className="cursor-pointer" src="https://img.beatxp.com/prod/custom/YouTube-Icon-White-Logo.wine.svg" alt="facebook" /></a>
                            <a target="_blank" rel="nofollow" href="https://www.linkedin.com/company/getbeatxp/"><img loading="lazy" className="cursor-pointer" src="https://img.beatxp.com/prod/custom/Linkedin.svg" alt="Linkedin" /></a>
                        </div>
                    </div>
                </div>
                <hr />
                <div id="copyright" className="footerText">
                    <span> ©2023 beatXP™ ®</span>
                    <span>beatXP™ <sup style={{ fontSize: '8px' }}>®</sup> is a trademark of GHV Medical Anchor</span>
                </div>
            </section>}
        </footer>
    );

}

export default Footer;