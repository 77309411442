import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { setAlert, setIsLoading } from '../../store/ui/uiSlice';
import './RequestDocs.scss';

function RequestDocs(props) {
    const [complainData, setComplainData] = useState({});
    const [images, setImages] = useState();
    const [video, setVideo] = useState();
    const [remarks, setRemarks] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsLoading(true));
        const searchParams = new URLSearchParams(props.location.search);
        if (searchParams.get('complainId') && searchParams.get('phone')) {
            let reqBody = {
                "id": searchParams.get('complainId'),
                "mobile": searchParams.get('phone')
            };
            let config = {
                method: 'POST',
                url: process.env.REACT_APP_BUILD_API_URL + 'trackComplaint',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: reqBody
            };
            Axios(config)
                .then(res => {
                    if (res.data.code && res.data.complaintData) {
                        dispatch(setIsLoading(false));
                        if ((Math.abs(new Date() - new Date(res.data.complaintData.additionalRemarksForComplaint[0]?.created_at)) / 36e5) >= 48) {
                            Swal.fire({
                                title: 'Error!',
                                text: 'Oops! This link has been expired.',
                                icon: 'error',
                                confirmButtonText: 'close',
                                backdrop: true,
                                allowOutsideClick: false
                            });
                            return;
                        } else if (res.data.complaintData.additionalRemarksForComplaint[0]?.status == 'done') {
                            Swal.fire({
                                title: 'Success',
                                text: 'You have already successfully submitted the documents.',
                                icon: 'success',
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false
                            });
                            return;
                        } else if (res.data.complaintData.complaint_status !== 'pending') {
                            dispatch(setIsLoading(false));
                            Swal.fire({
                                title: 'Error',
                                text: 'Oops! You cannot access this link.',
                                icon: 'error',
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false
                            });
                            return;
                        }
                        setComplainData(res.data.complaintData);
                    } else {
                        dispatch(setIsLoading(false));
                        setComplainData({});
                        Swal.fire({
                            title: 'Oops!',
                            text: res.data.message,
                            icon: 'error',
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false
                        });
                        return;
                    }
                }).catch(error => {
                    console.log(error);
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                })
        } else {
            dispatch(setIsLoading(false));
            setComplainData({});
            Swal.fire({
                title: 'Oops!',
                text: 'Unable to fetch details please check the URL',
                icon: 'error',
                showConfirmButton: false,
                backdrop: true,
                allowOutsideClick: false
            });
            return;
        }
    }, []);

    const getType = (type) => {
        switch (type) {
            case 'product_quality':
                return 'Product Quality';
            case 'delivery':
                return 'Delivery';
            case 'air_bike_installation':
                return 'Air Bike Installation';
            default:
                break;
        }
    }

    const removeItem = (file, type) => {
        if (type == 'images') {
            let updatedImgsList = [...images];
            updatedImgsList = updatedImgsList.filter(item => item.name != file);
            setImages(updatedImgsList);
        } else if (type == 'video') {
            setVideo("");
        }
    }

    const submitForm = () => {
        if (!images && !video) {
            dispatch(setAlert({ status: true, msg: 'Please upload images or a video atleast.', variant: 'danger' }));
            setTimeout(() => {
                dispatch(setAlert({ status: false, msg: '', variant: 'danger' }));
            }, 2500);
            return;
        }
        dispatch(setIsLoading(true));
        let reqBody = new FormData();
        let uploadedImgSize = 0;
        if (images && images.length) {
            [...images].forEach(item => {
                uploadedImgSize += item.size;
                reqBody.append('images', item);
            });
        }
        if (images && images.length && Math.round(uploadedImgSize / 1024) > 5120) {
            dispatch(setIsLoading(false));
            dispatch(setAlert({ status: true, msg: 'Maximum File upload size is 5MB.', variant: 'danger' }));
            setTimeout(() => {
                dispatch(setAlert({ status: false, msg: '', variant: 'danger' }));
            }, 2500);
            return;
        }
        if (video) {
            const uploadedSize = video[0]?.size;
            if (uploadedSize / (1024 * 1024 * 150) > 1) {
                dispatch(setIsLoading(false));
                dispatch(setAlert({ status: true, msg: 'Maximum video file size is 150MB.', variant: 'danger' }));
                setTimeout(() => {
                    dispatch(setAlert({ status: false, msg: '', variant: 'danger' }));
                }, 2500);
                return;
            }
            reqBody.append('video', video[0]);
        }
        if (remarks) {
            reqBody.append('user_remarks', remarks);
        }
        reqBody.append('id', complainData.additionalRemarksForComplaint[0]?.id);
        reqBody.append('complaint_id', complainData.id);
        let config = {
            method: 'POST',
            url: process.env.REACT_APP_JAVA_API_URL + 'api/support/saveAdditionalDataByUser',
            headers: {
                'Content-Type': 'application/json'
            },
            data: reqBody
        };
        document.body.scrollTo(0, 0);
        Axios(config)
            .then(res => {
                dispatch(setIsLoading(false));
                if (res.data.code) {
                    Swal.fire({
                        title: 'Success',
                        text: 'You have successfully submitted the documents.',
                        icon: 'success',
                        showConfirmButton: false,
                        backdrop: true,
                        allowOutsideClick: false
                    });
                    setIsSubmitted(true);
                    return;
                } else {
                    dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                    setIsSubmitted(false);
                    setTimeout(() => {
                        dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                    }, 3000);
                }
            }).catch(error => {
                setIsSubmitted(false);
                dispatch(setIsLoading(false));
                dispatch(setAlert({ status: true, msg: "Something went wrong", variant: "danger" }));
                setTimeout(() => {
                    dispatch(setAlert({ status: false, variant: "danger", msg: "" }));
                }, 3000);
            })

    }

    return (
        <div id="request-docs-wrapper" className={isSubmitted ? 'd-none' : ''}>
            {
                complainData && Object.keys(complainData).length ?
                    <div className='container text-left'>
                        <h5 className='text-center'>Kindly upload the requested documents below for our team to proceed with your complain.</h5>
                        <div className='mt-4'>
                            <div className='details'>
                                <p style={{ flex: '0.5' }}>
                                    <span className='font-weight-bold'>Complaint ID:&nbsp;</span>
                                    <span>{complainData.id}</span>
                                </p>
                                <p style={{ flex: '1' }}>
                                    <span className='font-weight-bold'>Complaint Type:&nbsp;</span>
                                    <span>{getType(complainData.complaint_type)}</span>
                                </p>
                                <p style={{ flex: '1' }}>
                                    <span className='font-weight-bold'>Order ID:&nbsp;</span>
                                    <span>{complainData.order_id}</span>
                                </p>
                            </div>
                            <div className='details'>
                                <p style={{ flex: '0.5' }}>
                                    <span className='font-weight-bold'>Name:&nbsp;</span>
                                    <span>{complainData.name}</span>
                                </p>
                                <p style={{ flex: '1' }}>
                                    <span className='font-weight-bold'>Phone no.:&nbsp;</span>
                                    <span>{complainData.mobile}</span>
                                </p>
                                <p style={{ flex: '1' }}>
                                    <span className='font-weight-bold'>E-mail:&nbsp;</span>
                                    <span>{complainData.email}</span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <h5 className='font-weight-bold'>Customer Support Remarks:&nbsp;</h5>
                            <p id='remarks'>{complainData.additionalRemarksForComplaint[0]?.requested_remarks}</p>{/* TODO update this key later */}
                        </div>
                        <div>
                            <textarea name='user-remarks'
                                placeholder='enter your remarks here'
                                onChange={(event) => {
                                    setRemarks(event.target.value);
                                }}
                            />
                        </div>
                        <section>
                            <div>
                                <input
                                    type={'file'}
                                    name="video-upload"
                                    id="video-upload"
                                    accept='video/*'
                                    onInput={(event) => {
                                        setVideo(event.target.files);
                                    }}
                                />
                                <label htmlFor='video-upload'>Upload Video</label>
                                <span className='my-2 text-info d-block'>**Video File upload limit is <b>150MB</b></span>
                            </div>
                            {
                                video ? <div className='file-box-wrapper border-0' style={{ width: '120px' }}>
                                    {
                                        <span className='file-box' key={video[0].name}>
                                            {video[0].name.slice(0, 10)}
                                            <button type='button' onClick={() => removeItem(video[0].name, 'video')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" /><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" /></svg>
                                            </button>
                                        </span>
                                    }
                                </div> : null
                            }
                        </section>
                        <section className='mt-5 flex-column'>
                            <div>
                                <input
                                    type={'file'}
                                    name="images-upload"
                                    id="images-upload"
                                    multiple
                                    accept='image/*, application/pdf'
                                    onInput={(event) => {
                                        setImages(event.target.files);
                                    }}
                                />
                                <label htmlFor='images-upload'>Upload Files</label>
                                <span className='my-2 text-info d-block'>**File upload limit is <b>5MB</b></span>
                            </div>
                            {
                                images ? <div className='file-box-wrapper border-0 h-auto'
                                    style={
                                        { marginTop: '30px' }
                                    }>
                                    {
                                        [...images].map(item => {
                                            if (item.type === 'application/pdf') {
                                                return (
                                                    <span className='file-box' key={item.name}>
                                                        {item.name.slice(0, 10)}
                                                        <button type='button' onClick={() => removeItem(item.name, 'images')}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" /><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" /></svg>
                                                        </button>
                                                    </span>
                                                )
                                            } else {
                                                return (
                                                    <span className='file-box' key={item.name}
                                                        style={{
                                                            backgroundImage: `url(${URL.createObjectURL(item)})`
                                                        }}>
                                                        {/* {item.name.slice(0, 10)} */}
                                                        <button type='button' onClick={() => removeItem(item.name, 'images')}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" /><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" /></svg>
                                                        </button>
                                                    </span>
                                                )
                                            }
                                        })
                                    }
                                </div> : null
                            }
                        </section>
                        <button type='button'
                            className='btn btn-brand w-50'
                            onClick={submitForm}>Submit</button>

                    </div> : null
            }
        </div>
    );
}

export default RequestDocs;