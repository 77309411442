import React from 'react';
import './cssfile.css'
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import image1 from '../../assets/image_1@3x.png'
import { trackOrder } from '../../networkCalls.js'
import HomeComponent from '../home';
import { connect } from 'react-redux';
import { setAlert, setIsLoading } from '../../store/ui/uiSlice';
import Axios from 'axios';
import ComplaintTrack from '../complaintTrack/ComplaintTrack';
class LoginComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orderNo: "",
      identifier: "",
      show: false,
      msg: '',
      isComplaintForm: false
    };
  }

  toggleAlert = (obj) => {
    this.props.setAlertHandler(obj);
    window.setTimeout(() => {
      this.props.setAlertHandler({ status: false, msg: "", variant: "danger" });
    }, 3000);
  }

  onOrderNoChange = (e) => {
    this.setState({ orderNo: e.target.value })
  }

  onIdentifierChanged = (e) => {
    this.setState({ identifier: e.target.value.slice(0, e.target.maxLength) })
  }

  submit = (e) => {
    this.props.setIsLoadingHandler(true);
    e.preventDefault();
    let orderNo = this.state.orderNo;
    let identifier = this.state.identifier
    if (this.state.orderNo.charAt(0) === '#') {
      orderNo = this.state.orderNo.substring(1);
    }

    if (identifier.match(/^(\d)\1+$/g) || (this.state.identifier).toString().charAt(0) < 6) {
      this.props.setIsLoadingHandler(false);
      this.toggleAlert({ status: true, msg: "Enter valid phone number", variant: "danger" });
      return;
    }

    trackOrder(orderNo, identifier).then(res => {
      if (res.code && Array.isArray(res.data) && res.data.length) {
        this.props.setIsLoadingHandler(false);
        const url = process.env.REACT_APP_NODE_ENV == 'development' ? '/support-dashboard/track-order?id=' + this.state.orderNo + '&phone=' + this.state.identifier : '/track-order?id=' + this.state.orderNo + '&phone=' + this.state.identifier;
        this.props.history.push({
          pathname: url,
          state: { orderDetail: res.data }
        });
        this.setState({ identifier: "", orderNo: "" });
      } else {
        setTimeout(() => {
          this.props.setIsLoadingHandler(false);
          this.toggleAlert({ status: true, msg: res.message, variant: "danger" });
        }, 2000)
      }
    }).catch((error) => {
      this.props.setIsLoadingHandler(false);
      this.toggleAlert({ status: true, msg: error.message, variant: "danger" });
    })
  }

  fetchComplaintDetails = (e) => {
    e.preventDefault();
    this.props.setIsLoadingHandler(true);
    let orderNo = this.state.orderNo;
    let identifier = this.state.identifier
    if (this.state.orderNo.charAt(0) === '#') {
      orderNo = this.state.orderNo.substring(1);
    }

    if (identifier.match(/^(\d)\1+$/g) || (this.state.identifier).toString().charAt(0) < 6) {
      this.props.setIsLoadingHandler(false);
      this.toggleAlert({ status: true, msg: "Enter valid phone number", variant: "danger" });
      return;
    }

    let reqBody = {
      "id": this.state.orderNo,
      "mobile": this.state.identifier
    };
    let config = {
      method: 'POST',
      url: process.env.REACT_APP_BUILD_API_URL + 'trackComplaint',
      headers: {
        'Content-Type': 'application/json'
      },
      data: reqBody
    };
    Axios(config).then(res => {
      if (res.data.code === 1 && res.data.complaintData) {
        this.props.setIsLoadingHandler(false);
        this.props.history.push({
          pathname: process.env.REACT_APP_NODE_ENV === 'development' ? '/support-dashboard/track-complaint' : '/track-complaint',
          search: `?id=${this.state.orderNo}&phone=${this.state.identifier}`,
          state: { complaintData: res.data.complaintData }
        });
        this.setState({ identifier: "", orderNo: "" });
      } else {
        setTimeout(() => {
          this.props.setIsLoadingHandler(false);
          this.toggleAlert({ status: true, msg: res.data.message, variant: "danger" });
        }, 1000)
      }
    }).catch(error => {
      this.props.setIsLoadingHandler(false);
      this.toggleAlert({ status: true, msg: error.message, variant: "danger" });
    })
  }

  componentDidMount() {
    if (this.props.location.pathname.includes('complaint')) {
      this.setState(() => {
        return {
          isComplaintForm: true
        }
      })
    }
  }

  render() {
    const { search } = this.props.location;
    if (search) {
      if (this.props.location.pathname.includes('complaint')) return <ComplaintTrack location={this.props.location} history={this.props.history} />
      else return <HomeComponent location={this.props.location} history={this.props.history} />
    }
    return (
      <div className="login">
        <Card className="card-login shadow p-3 mb-5 bg-white rounded">

          <Card.Body>

            <Row className="login-body">
              <Col lg={7} md={7} xs={12}>
                <img className="login-image" alt="Beat XP" src={image1} />
              </Col>
              <Col lg={5} md={7} xs={12} className="p-0">
                <h3 className='mb-0'><b>Track Your {this.state.isComplaintForm ? 'Complaint' : 'Order'}</b></h3>
                <Form onSubmit={(e) => {
                  if (this.state.isComplaintForm) this.fetchComplaintDetails(e);
                  else this.submit(e);
                }}>
                  {
                    this.state.isComplaintForm ? <div className='position-relative'>
                      <span id="complain">BX-C-</span>
                      <Form.Control
                        placeholder={'Complaint ID'}
                        type={"number"}
                        value={this.state.orderNo}
                        onChange={this.onOrderNoChange}
                        required
                        style={{
                          paddingLeft: '100px'
                        }}
                      />
                    </div> : <Form.Control
                      placeholder={'Order Number'}
                      type={"text"}
                      value={this.state.orderNo}
                      onChange={this.onOrderNoChange}
                      required
                    />
                  }
                  <Form.Control placeholder="Mobile Number"
                    type="number"
                    maxLength={10}
                    pattern="[0-9]*"
                    inputMode='numeric'
                    value={this.state.identifier}
                    onChange={this.onIdentifierChanged}
                    required />
                  <Button type="submit" className="submit-button">Submit</Button>

                </Form>
              </Col>
            </Row>


          </Card.Body>

        </Card>
      </div>
    )
  }


}

const mapStateToProps = (state) => {
  return {
    ui: state.ui
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoadingHandler: (status) => dispatch(setIsLoading(status)),
    setAlertHandler: (obj) => dispatch(setAlert(obj))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
