import React from 'react';
import './cssfile.css'
import { Button, Alert } from 'react-bootstrap';
import moment from "moment";
import { trackOrder } from '../../networkCalls';
import { setIsLoading } from '../../store/ui/uiSlice';
import { connect } from 'react-redux';

class HomeComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      trackingStatus: [],
      show: false,
      changeButtonText: "",
      msg: ''
    };

  }
  componentDidMount() {
    this.props.setIsLoadingHandler(true);
    if (this.props.location.state && this.props.location.state.orderDetail) {
      const orderDetail = this.props.location.state.orderDetail;
      if (Array.isArray(orderDetail) && orderDetail.length) {
        let trackingStatus = [];
        if (orderDetail[0].trackingStatus && orderDetail[0].trackingStatus.length) {
          trackingStatus = orderDetail[0].trackingStatus.map(item => {
            return {
              'timestamp': item.timestamp,
              'status': item.status,
              'code': item.clickpostStatusCode,
              'location': item.location,
              'status': item.status,
              'clickpostStatusCode': item.clickpostStatusCode
            }
          });
        }
        this.setState({ orderDetails: orderDetail, trackingStatus: trackingStatus }, () => {
          this.props.setIsLoadingHandler(false);
        });
        return;
      } else this.props.setIsLoadingHandler(false);
      return;

    }
    const searchParams = new URLSearchParams(this.props.location.search);
    if (this.props.location.search && searchParams.get('id')) {
      trackOrder(searchParams.get('id'), searchParams.get('phone')).then(res => {
        if (res.code && Array.isArray(res.data) && res.data.length) {
          this.setState({ orderDetails: res.data }, () => { this.props.setIsLoadingHandler(false); });
          return;
        } else {
          this.props.setIsLoadingHandler(false);
          this.toggleAlert(true, res.message);
          this.props.setIsLoadingHandler(false)
          return;
        }
      }).catch((error) => {
        this.props.setIsLoadingHandler(false);
        this.toggleAlert(true, "error");
        this.props.setIsLoadingHandler(false)
        return;
      })
    } else { this.props.setIsLoadingHandler(false); this.trackAgain() }
  }

  toggleAlert = (show, msg) => {
    this.setState({ show: show, msg: msg }, () => {
      window.setTimeout(() => {
        this.setState({ show: false })
      }, 3000)
    });

  }
  trackAgain = (e) => {
    if(e){
      e.preventDefault();
    }
    this.props.history.push('/track')
  }

  isShipped = (item) => {
    return item.trackingStatus.find(item => item.clickpostStatusCode == 5 || item.clickpostStatusCode == 4);
  }

  isOOD = (item) => {
    return item.trackingStatus.find(item => item.clickpostStatusCode == 6 || item.clickpostStatusCode == 8 || item.clickpostStatusCode == 9);
  }
  render() {
    return (
      <div>

        <Alert className="track-alert"
          show={this.state.show}
          variant="danger"
          onClose={() => this.toggleAlert(false)} >
          {this.state.msg}
        </Alert>
        <div className="order">

          <h2 className="orderTitle">Tracking Details</h2>
          {
            this.state.orderDetails.map(item => {
              return (
                <div key={item.itemId}>
                  <div className="timeline">
                    <div className="events">
                      <ul className='timeline-ul'>
                        <li>
                          <p className={!this.isShipped(item) && !this.isOOD(item) ? item.trackingStatus.find(item => item.status == "canceled") ? "anchor selected" : "anchor selected blinking" : "anchor selected"}>Order Placed</p>
                          <p>{moment(item.orderDate).format("DD MMM YYYY")}<span className='comma'>, </span><br className="timeline-space" />
                            {moment(item.orderDate).add(5, 'hours').add('30', 'minutes').format("hh:mm A")}</p>
                        </li>

                        <li>
                          <p className={this.isShipped(item) ? item.trackingStatus[item.trackingStatus.length - 1].clickpostStatusCode == 4 ? "anchor selected blinking" : "anchor selected" : "anchor "}>Shipped</p>
                          <p>
                            {this.isShipped(item) ?
                              moment(this.isShipped(item).timestamp).format("DD MMM YYYY") :
                              <span className="empty-whitespace">&nbsp;</span>
                            }
                            <br className="timeline-space" />
                            <span className='comma timeline-space'>, </span>
                            {this.isShipped(item) ? moment(this.isShipped(item).timestamp).add(5, 'hours').add('30', 'minutes').format("hh:mm A") : <span className="empty-whitespace">&nbsp;</span>} <br className="timeline-space" /><span style={{ display: "table", color: 'darkblue' }}>{this.isShipped(item) ? this.isShipped(item).location : " "} </span></p>
                        </li>

                        <li>
                          <p className={this.isOOD(item) ? item.trackingStatus[item.trackingStatus.length - 1].clickpostStatusCode == 6 ? "anchor selected blinking" : "anchor selected" : "anchor "}>{item?.trackingStatus[item?.trackingStatus.length - 1]?.clickpostStatusCode == 9 ? "Failed Delivery " : "Out For Delivery"}</p>
                          <p>{this.isOOD(item) ? moment(this.isOOD(item).timestamp).format("DD MMM YYYY") : <span className="empty-whitespace">&nbsp;</span>}
                            <br className="timeline-space" />
                            <span className='comma'>, </span>
                            {this.isOOD(item) ? moment(this.isOOD(item).timestamp).add(5, 'hours').add('30', 'minutes').format("hh:mm A") : <span className="empty-whitespace">&nbsp;</span>}<br className="timeline-space" /><span style={{ display: "table", color: 'darkblue' }}>{this.isOOD(item) ? this.isOOD(item).location : " "} </span></p>
                        </li>

                        {item.trackingStatus[item.trackingStatus.length - 1]?.status == "canceled" ? "" : <li>
                          <p className={item.trackingStatus.find(item => item.clickpostStatusCode == 8) ? "anchor selected" : "anchor "}> Delivered</p>
                          <p>{item.trackingStatus.find(item => item.clickpostStatusCode == 8) ? moment(item.trackingStatus.find(item => item.clickpostStatusCode == 8).timestamp).format("DD MMM YYYY") : <span className="empty-whitespace">&nbsp;</span>}
                            <br className="timeline-space" />
                            <span className='comma'>, </span>
                            {item.trackingStatus.find(item => item.clickpostStatusCode == 8) ? moment(item.trackingStatus.find(item => item.clickpostStatusCode == 8).timestamp).add(5, 'hours').add('30', 'minutes').format("hh:mm A") : <span className="empty-whitespace">&nbsp;</span>} <br className="timeline-space" /><span style={{ display: "table" }}>{item.trackingStatus.find(item => item.clickpostStatusCode == 8) ? item.trackingStatus.find(item => item.clickpostStatusCode == 8).location : " "} </span></p>
                        </li>}

                        {
                          item.trackingStatus.find(item => item.status == "canceled") ? <li>
                            <p className={item.trackingStatus.find(item => item.status == "canceled") ? "anchor selected" : "anchor "}>Cancelled</p>
                            <p>
                              {item.trackingStatus.find(item => item.status == "canceled")?.timestamp ? moment(item.trackingStatus.find(item => item.status == "canceled").timestamp).format("DD MMM YYYY")
                                : <span className="empty-whitespace">&nbsp;</span>}
                              <br className="timeline-space" />
                              <span className='comma'>, </span>
                              {item.trackingStatus.find(item => item.status == "canceled")?.timestamp ? moment(item.trackingStatus.find(item => item.status == "canceled").timestamp).add(5, 'hours').add('30', 'minutes').format("hh:mm A") : <span className="empty-whitespace">&nbsp;</span>}
                            </p>
                          </li> : ''}
                      </ul>
                    </div>
                  </div>
                  <div className='order-wrapper'>
                    <div>
                      <span className='font-weight-bold'>Order ID&nbsp;: </span>
                      <span>{item.orderId}</span>
                    </div>
                    <div>
                      <span className='font-weight-bold'>Order Status&nbsp;: </span>
                      <span>{Array.isArray(item.trackingStatus) && item.trackingStatus.length ? item.trackingStatus[0].status == "canceled" ? "Cancelled" : item.status : 'Processing'}</span>
                    </div>
                    <div>
                      <span className='font-weight-bold'>Product Name&nbsp;: </span>
                      <span>{item.productDetails.productName}</span>
                    </div>
                    <div className='d-none'>
                      <span className='font-weight-bold'>Product Price&nbsp;: </span>
                      <span>₹&nbsp;{item.productDetails.productPrice}</span>
                    </div>
                    {
                      item.awb ? <div>
                        <span className='font-weight-bold'>Detailed Tracking</span>
                        <span><a className='d-flex align-items-center' href={`https://beatxp.clickpost.ai/?waybill=${item.awb}`} target={'_blank'} >Track Here<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{
                          height: '20px',
                          marginLeft: '10px'
                        }}>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                        </svg>

                        </a></span>
                      </div> : null
                    }
                  </div>

                </div>
              )
            })
          }
          <a href="https://www.beatxp.com/" rel="noopener noreferrer" target="_blank"><Button type="submit" className="buy-button"><b>Buy More</b></Button></a>
          <Button type="submit" className="track-button" onClick={this.trackAgain}><b>Track Again</b></Button>

        </div>
      </div >
    )



  }


}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoadingHandler: (status) => dispatch(setIsLoading(status))
  }
};

export default connect(null, mapDispatchToProps)(HomeComponent)
