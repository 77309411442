import React from 'react';
import ConsoleComplain from './ConsoleComplain';
import Complain from './Complain';

function ComplaintWrapper(props) {

    const searchParams = new URLSearchParams(props.location.search);
    if (searchParams.get('email') || searchParams.get('token')) {
        return <ConsoleComplain {...props} />
    } else return <Complain {...props} />
}

export default ComplaintWrapper;