import React, { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { Control } from '../../components/CustomControl/CustomControl';
import { complaintTypeOptions, genderOptions, subDeliveryOptions } from '../../helper/data';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.8443 0.303668C1.4392 -0.101223 0.7828 -0.101223 0.3776 0.303668C-0.0272007 0.708764 -0.0272008 1.36517 0.3776 1.77031L6.30367 7.69633C6.70876 8.10122 7.36517 8.10122 7.77031 7.69633L13.6963 1.77031C14.1012 1.36521 14.1012 0.708807 13.6963 0.303668C13.2912 -0.101222 12.6348 -0.101222 12.2297 0.303668L7.0341 5.49926L1.8443 0.303668Z" fill="#FF8300" />
                </svg>
            </components.DropdownIndicator>
        )
    );
};

function Form1(props) {
    const getPhoneNum = (event) => {
        if (event.target.value.toString().length !== 10) props.setForm1DetailsHandler({ ...props.form1Details, phoneNum: event.target.value.slice(0, 10) });
        else props.setForm1DetailsHandler({ ...props.form1Details, phoneNum: event.target.value });
    }

    const enterAge = (event) => {
        props.setForm1DetailsHandler({ ...props.form1Details, age: event.target.value });
    }

    const nameRef = useRef("");
    const [isSubOptionsShown, setShowSubOptions] = useState(false);

    useEffect(() => {
        if (nameRef) nameRef.current.focus();
    }, []);

    useEffect(() => {
        if (props.form1Details?.type?.value === 'delivery') {
            setShowSubOptions(true);
        } else {
            setShowSubOptions(false);
        }
    }, [props.form1Details?.type])

    let formCorrect = true

    if(props.form1Details){
        for (const [key, value] of Object.entries(props.form1Details)) {
            if(key !== "invoiceNumber" && !value){
                formCorrect = false
            }
        }
    }

    return (
        <div>
            <form>
                <div className="did-floating-label-content">
                    <label className="did-floating-label">Name<span className='text-danger'>*</span></label>
                    <input className="did-floating-input" type="text" placeholder="Enter your name"
                        value={props.form1Details.name}
                        ref={nameRef}
                        maxLength={20}
                        onChange={(event) => props.setForm1DetailsHandler({ ...props.form1Details, name: event.target.value })} />
                </div>
                <div className="did-floating-label-content">
                    <label className="did-floating-label">Email ID<span className='text-danger'>*</span></label>
                    <input className={`did-floating-input ${props.form1Errors.email ? 'border-error' : ''}`}
                        type="text" placeholder="Enter your email id"
                        value={props.form1Details.email}
                        onChange={(event) => props.setForm1DetailsHandler({ ...props.form1Details, email: event.target.value })} />
                    {props.form1Errors && props.form1Errors.email ? <span className='error'>Please enter a valid email id</span> : null}
                </div>
                <div className="did-floating-label-content">
                    <label className="did-floating-label">Phone Number<span className='text-danger'>*</span></label>

                    <input className={`did-floating-input ${props.form1Errors.phoneNum ? 'border-error' : ''}`}
                        type="number"
                        value={props.form1Details.phoneNum}
                        placeholder="Enter your mobile number"
                        onChange={(event) => getPhoneNum(event)} />
                    {props.form1Errors && props.form1Errors.phoneNum ? <span className='error'>Please enter a valid phone number</span> : null}
                    <span className='text-brand'>Please note that we will send the OTP to the above number.</span>
                </div>

                <div className='did-floating-label-content'>
                    <label className='did-floating-label'>Age<span className='text-danger'>*</span></label>

                    <input className={`did-floating-input ${props.form1Errors.age ? 'border-error' : ''}`}
                        type="number"
                        value={props.form1Details.age}
                        placeholder="Enter your age"
                        onChange={(event) => enterAge(event)} />
                    {props.form1Errors && props.form1Errors.age ? <span className='error'>Please enter a valid age</span> : null}
                </div>

                <div className='did-floating-label-content'>
                    <label className='did-floating-label'>Gender<span className='text-danger'>*</span></label>

                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="gender"
                        components={{ DropdownIndicator }}
                        placeholder="Gender"
                        options={genderOptions}
                        styles={{
                            menu: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: 2,
                            }),
                            menuList: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: 'left',
                            }),
                            singleValue: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: 'left'
                            }),
                            control: (baseStyles) => ({
                                ...baseStyles,
                                fontSize: '16px',
                                background: '#fff',
                                color: '#323840',
                                border: '1px solid var(--input-border-color)',
                                borderColor: 'var(--input-border-color)',
                                borderRadius: '10px',
                                boxSizing: 'border-box',
                                height: '50px',
                                textAlign: 'left',
                                '&:hover': {
                                    borderColor: 'var(--input-border-color)'
                                }
                            }),
                            container: (baseStyles) => ({
                                ...baseStyles,
                                width: '100%'
                            }),
                            placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: '#C1C1C1',
                                fontSize: '16px',
                                fontFamily: 'Lato'
                            }),
                            indicatorSeparator: (baseStyles) => ({
                                display: 'none'
                            }),
                            dropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                paddingRight: '21px',
                                '& svg': {
                                    fill: 'var(--brand-color)'
                                }
                            })
                        }}
                        value={props.form1Details.gender}
                        // onBlur={getFormErrors}
                        // defaultValue={props.form3Details.type}
                        onChange={(value) => {
                            props.setForm1DetailsHandler((prevState) => ({
                                ...prevState,
                                gender: value
                            }));
                        }}
                    />
                </div>

                <div className="did-floating-label-content">
                    <label className="did-floating-label">Complaint/Request Type<span className='text-danger'>*</span></label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="type"
                        components={{ DropdownIndicator }}
                        placeholder="Select your complaint/request type"
                        options={complaintTypeOptions}
                        styles={{
                            menu: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: 2,
                            }),
                            menuList: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: 'left',
                            }),
                            singleValue: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: 'left'
                            }),
                            control: (baseStyles) => ({
                                ...baseStyles,
                                fontSize: '16px',
                                background: '#fff',
                                color: '#323840',
                                border: '1px solid var(--input-border-color)',
                                borderColor: 'var(--input-border-color)',
                                borderRadius: '10px',
                                boxSizing: 'border-box',
                                height: '50px',
                                textAlign: 'left',
                                '&:hover': {
                                    borderColor: 'var(--input-border-color)'
                                }
                            }),
                            container: (baseStyles) => ({
                                ...baseStyles,
                                width: '100%'
                            }),
                            placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: '#C1C1C1',
                                fontSize: '16px',
                                fontFamily: 'Lato'
                            }),
                            indicatorSeparator: (baseStyles) => ({
                                display: 'none'
                            }),
                            dropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                paddingRight: '21px',
                                '& svg': {
                                    fill: 'var(--brand-color)'
                                }
                            })
                        }}
                        value={props.form1Details.type}
                        // onBlur={getFormErrors}
                        // defaultValue={props.form3Details.type}
                        onChange={(value) => {
                            props.setForm1DetailsHandler((prevState) => ({
                                ...prevState,
                                type: value
                            }));
                        }}
                    />
                </div>

                {isSubOptionsShown && <div className="did-floating-label-content">
                    <label className="did-floating-label">Select Delivery related Issue<span className='text-danger'>*</span></label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="type"
                        components={{ DropdownIndicator }}
                        placeholder="Select your delivery related issue"
                        options={subDeliveryOptions}
                        styles={{
                            menu: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: 2,
                            }),
                            menuList: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: 'left',
                            }),
                            singleValue: (baseStyles) => ({
                                ...baseStyles,
                                textAlign: 'left'
                            }),
                            control: (baseStyles) => ({
                                ...baseStyles,
                                fontSize: '16px',
                                background: '#fff',
                                color: '#323840',
                                border: props.form1Errors?.type2 ? '1px solid var(--error-color)' : '1px solid var(--input-border-color)',
                                borderRadius: '10px',
                                boxSizing: 'border-box',
                                height: '50px',
                                textAlign: 'left',
                                '&:hover': {
                                    borderColor: props.form1Errors?.type2 ? 'var(--error-color)' : 'var(--input-border-color)'
                                }
                            }),
                            container: (baseStyles) => ({
                                ...baseStyles,
                                width: '100%'
                            }),
                            placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: '#C1C1C1',
                                fontSize: '16px',
                                fontFamily: 'Lato'
                            }),
                            indicatorSeparator: (baseStyles) => ({
                                display: 'none'
                            }),
                            dropdownIndicator: (baseStyles) => ({
                                ...baseStyles,
                                paddingRight: '21px',
                                '& svg': {
                                    fill: 'var(--brand-color)'
                                }
                            })
                        }}
                        ref={props.subOptionRef}
                    />
                </div>}

                {
                    props.form1Details.type?.value === 'product_quality' && <div className="did-floating-label-content">
                        <label className="did-floating-label">Invoice Number
                            {/* <span className='text-danger'>*</span> */}
                        </label>
                        <input className="did-floating-input" type="text" placeholder="Enter your Invoice Number"
                            value={props.form1Details.invoiceNumber}
                            maxLength={20}
                            onChange={(event) => props.setForm1DetailsHandler({ ...props.form1Details, invoiceNumber: event.target.value })} />
                        {/* {props.form1Errors && props.form1Errors.invoiceNumber ? <span className='error'>Please enter your order id</span> : null} */}
                    </div>
                }

                <button
                    type='button'
                    className='btn btn-primary btn-brand'
                    onClick={props.submitForm1Handler}
                    style={{
                        cursor: !props.form1Details
                            ||
                            !formCorrect
                            ? 'not-allowed !important'
                            : ''
                    }}
                    disabled={!props.form1Details
                        || !formCorrect}>
                    Next
                </button>
            </form>
        </div>
    );
}

export default Form1;