import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    showAlert: false,
    alertMessage: '',
    variant: "success",
    isMobile: false,
    category: []
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setIsLoading: (state, action) =>{
            state.isLoading = action.payload;
        },
        setAlert: (state, action) =>{
            state.showAlert = action.payload.status;
            state.alertMessage = action.payload.msg;
            state.variant = action.payload.variant;
        },
        setIsMobile: (state, action) => {
            state.isMobile = action.payload < 768;
        },
        setCategory: (state, action) => {
            state.category = action.payload;
        }
    }
});

export const { setIsLoading, setAlert, setIsMobile, setCategory } = uiSlice.actions;
export default uiSlice.reducer;
