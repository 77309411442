import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert, setIsLoading } from '../../store/ui/uiSlice';
import './ComplaintTrack.scss';
import { useHistory } from 'react-router-dom';

function ComplaintTrack(props) {
    const { location } = props;
    const [complaintData, setcomplaintData] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setIsLoading(true));
        const searchParams = new URLSearchParams(location.search);

        if (location.state && location.state.complaintData) {
            setcomplaintData(location.state.complaintData);
            setTimeout(() => {
                dispatch(setIsLoading(false));
                history.replace({ ...location, state: undefined });
            }, 500);
            return;
        }

        if(!searchParams.get('id') || !searchParams.get('phone')){
            dispatch(setIsLoading(false));
            history.push('/track-complaint')
        }

        if (location.search && searchParams.get('id')) {
            let reqBody = {
                "id": searchParams.get('id'),
                "mobile": searchParams.get('phone')
            };
            let config = {
                method: 'POST',
                url: process.env.REACT_APP_BUILD_API_URL + 'trackComplaint',
                data: reqBody
            };
            Axios(config)
                .then(res => {
                    if (res.data.code === 1 && res.data.complaintData) {
                        setcomplaintData(res.data.complaintData);
                        dispatch(setIsLoading(false));
                    } else {
                        setcomplaintData({});
                        setTimeout(() => {
                            dispatch(setIsLoading(false));
                            dispatch(setAlert({ status: true, msg: res.data.message, variant: "danger" }));
                        }, 2000)
                    }
                }).catch(error => {
                    setcomplaintData({});
                    dispatch(setIsLoading(false));
                    dispatch(setAlert({ status: true, msg: error.message, variant: "danger" }));
                })
        }
    }, []);

    const getStatus = (type) => {
        switch (type) {
            case 'open':
                return <span className='badge badge-warning'>OPEN</span>;
            case 'reopen':
                return <span className='badge badge-secondary'>RE-OPENED</span>;
            case 'claimed':
                return <span className='badge badge-primary'>CLAIMED</span>;
            case 'done':
                return <span className='badge badge-success'>DONE</span>;
            case 'escalated':
                return <span className='badge badge-danger'>ESCALATED</span>;
            default:
                break;
        }
    }
    const getType = (type) => {
        switch (type) {
            case 'product_quality':
                return <span className=''>Product Quality</span>;
            case 'delivery':
                return <span className=''>Delivery</span>;
            case 'air_bike_installation':
                return <span className=''>Air Bike Installation</span>;
            default:
                break;
        }
    }
    return (
        <div className='complaint-container'>
            {
                complaintData ? <>
                    <div>
                        <svg id='complaint-svg' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                        </svg>

                    </div>
                    <div id='wrapper'
                        style={{
                            width: complaintData && !Object.keys(complaintData).length ? '60%' : 'fit-content'
                        }}>

                        <div className='d-flex'>
                            <p className='font-weight-bold'>Complaint ID:</p>
                            <span>{complaintData.id || '-'}</span>
                        </div>
                        <div className='d-flex'>
                            <p className='font-weight-bold'>Status:</p>
                            <span>{getStatus(complaintData.complaint_status) || '-'}</span>
                        </div>
                        <div className='d-flex'>
                            <p className='font-weight-bold'>Complaint Type:</p>
                            <span>{getType(complaintData.complaint_type) || '-'}</span>
                        </div>
                        <div className='d-flex'>
                            <p className='font-weight-bold'>Name:</p>
                            <span>{complaintData.name || '-'}</span>
                        </div>
                        <div className='d-flex'>
                            <p className='font-weight-bold'>Email:</p>
                            <span>{complaintData.email || '-'}</span>
                        </div>
                        <div className='d-flex'>
                            <p className='font-weight-bold'>Mobile:</p>
                            <span>{complaintData.mobile || '-'}</span>
                        </div>
                        <div className='d-flex'>
                            <p className='font-weight-bold'>Generated on:</p>
                            <span>{complaintData.created_at ?
                                moment(complaintData.created_at).format('DD MMM YYYY') : '-'}</span>
                        </div>
                    </div> </> : null
            }
        </div>
    );
}

export default ComplaintTrack;