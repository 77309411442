import React from 'react'
import { useHistory } from 'react-router-dom';

import './thankYou.css'
function ThankYou() {

    return (
        <section className='mx-3'>
            <div className="thank-you-page">
                <div className="thank-you-logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="101" viewBox="0 0 100 101" fill="none">
                        <g clipPath="url(#clip0_780_30058)">
                            <path d="M10.1418 72.3221C5.97918 67.9817 0.623411 62.396 0.0501699 54.9275C-0.586765 46.6273 4.99017 39.9979 8.311 36.8193C9.40623 35.7687 10.6451 34.879 11.9904 34.1768C14.5673 32.8347 17.6549 30.6729 19.835 27.176C22.5064 22.8907 21.6816 20.0225 23.1546 15.8895C26.9637 5.201 40.7214 1.25978 41.6106 1.0178C51.8585 -1.77118 60.5615 1.93709 65.1572 4.6581C66.99 5.74315 68.5705 7.19957 69.8539 8.89924C70.9553 10.3579 72.4769 11.9836 74.5404 13.3666C78.7495 16.1881 81.7343 15.6698 85.5614 17.0843C92.269 19.5641 97.1769 26.3605 98.9724 32.7529C101.25 40.8608 98.4172 47.9539 96.4119 51.625C95.2529 53.7436 94.5764 56.0922 94.4308 58.5027C94.3699 59.5207 94.3293 60.6364 94.3248 61.8323C94.3012 67.9047 95.231 69.2982 94.9888 72.1887C94.2766 80.6845 84.776 86.2063 83.9676 86.6619C76.8913 90.6518 69.8271 89.6315 66.746 88.8952C65.2143 88.5294 63.6386 88.3744 62.0668 88.4719C60.9917 88.5381 59.9235 88.6877 58.8716 88.9193C53.8403 90.0304 51.9155 92.4563 48.6473 94.0978C42.4861 97.1927 33.9431 96.2987 27.9331 92.5044C23.5851 89.7596 21.1472 85.8653 19.8084 82.8684C18.7276 80.4476 17.0568 78.3417 14.973 76.7024C13.2625 75.3567 11.6482 73.893 10.1418 72.3221Z" fill="#EEECFB" />
                            <path d="M17.3635 7.45026C16.9136 5.57155 15.6907 4.34082 13.5547 3.87214C13.5173 3.86439 13.4836 3.84458 13.4586 3.81577C13.4336 3.78696 13.4188 3.75073 13.4164 3.71267C13.414 3.6746 13.4241 3.6368 13.4453 3.60509C13.4665 3.57337 13.4975 3.54949 13.5336 3.53712C15.4991 2.86007 16.7493 1.72069 17.2842 0.118993C17.3417 -0.0530294 17.5864 -0.0332192 17.6213 0.144069C17.9723 1.9335 19.2613 2.99749 21.2295 3.53462C21.2671 3.54548 21.2999 3.56858 21.3229 3.60026C21.3458 3.63193 21.3575 3.67036 21.3562 3.70945C21.3548 3.74854 21.3404 3.78605 21.3153 3.81603C21.2901 3.846 21.2557 3.86672 21.2174 3.8749C19.3144 4.26935 18.181 5.50184 17.703 7.4475C17.6604 7.62579 17.4064 7.62956 17.3635 7.45026Z" fill="#96D7FF" />
                            <path d="M4.88361 26.2523C4.4844 24.7041 3.45903 23.6803 1.69893 23.2723C1.52942 23.2329 1.5204 22.9964 1.68414 22.9377C3.29955 22.362 4.34137 21.416 4.80963 20.0999C4.82261 20.0642 4.84682 20.0337 4.87861 20.0129C4.9104 19.9922 4.94806 19.9823 4.98595 19.9847C5.02383 19.9872 5.05991 20.0019 5.08875 20.0266C5.11759 20.0513 5.13764 20.0846 5.1459 20.1217C5.46613 21.5884 6.53412 22.4758 8.15078 22.9383C8.32481 22.9884 8.3198 23.2357 8.14327 23.2755C6.58353 23.6283 5.63941 24.651 5.22239 26.2471C5.17575 26.4208 4.92824 26.4263 4.88361 26.2523Z" fill="#C9C1F5" />
                            <path d="M88.4851 6.85403C88.0859 5.30583 87.0606 4.28197 85.3005 3.87398C85.131 3.83461 85.1219 3.59814 85.2859 3.53946C86.901 2.96338 87.9427 2.01734 88.4112 0.701343C88.4242 0.665669 88.4484 0.635173 88.4802 0.614433C88.512 0.593693 88.5497 0.583828 88.5876 0.586314C88.6255 0.588801 88.6615 0.603505 88.6904 0.628223C88.7192 0.652942 88.7392 0.686342 88.7474 0.72341C89.0677 2.19011 90.1356 3.07756 91.7526 3.53997C91.9266 3.59012 91.9216 3.83737 91.7448 3.87724C90.1853 4.23006 89.2412 5.25267 88.8239 6.84877C88.7773 7.02254 88.53 7.02806 88.4851 6.85403Z" fill="#FF99B1" />
                            <path d="M96.6062 30.9126C96.207 29.3644 95.1817 28.3406 93.4216 27.9323C93.252 27.8932 93.243 27.6565 93.407 27.5978C95.0221 27.0221 96.0638 26.0761 96.5323 24.7599C96.5453 24.7243 96.5695 24.6938 96.6013 24.673C96.6331 24.6523 96.6708 24.6424 96.7087 24.6449C96.7466 24.6474 96.7826 24.6621 96.8114 24.6868C96.8403 24.7115 96.8603 24.7449 96.8685 24.782C97.1888 26.2487 98.2567 27.1362 99.8734 27.5983C100.048 27.6485 100.043 27.8957 99.8659 27.9358C98.3064 28.2887 97.3623 29.3113 96.945 30.9074C96.8984 31.0811 96.6511 31.0864 96.6062 30.9126Z" fill="#96D7FF" />
                            <path d="M93.7481 100.229C93.3489 98.6808 92.3235 97.6567 90.5622 97.2487C90.3927 97.2096 90.3836 96.9729 90.5476 96.9142C92.1627 96.3385 93.2045 95.3925 93.6729 94.0763C93.6859 94.0407 93.7101 94.0102 93.7419 93.9894C93.7737 93.9687 93.8114 93.9588 93.8493 93.9613C93.8872 93.9638 93.9232 93.9785 93.952 94.0032C93.9809 94.0279 94.0009 94.0613 94.0092 94.0984C94.3294 95.5649 95.3974 96.4526 97.0143 96.9147C97.1883 96.9649 97.1833 97.2121 97.0065 97.2522C95.447 97.6048 94.5029 98.6274 94.0856 100.224C94.0402 100.397 93.793 100.403 93.7481 100.229Z" fill="#C9C1F5" />
                            <path d="M6.08604 92.1118C5.68683 90.5636 4.66146 89.5395 2.90011 89.1315C2.73059 89.0924 2.72157 88.8557 2.88532 88.797C4.50072 88.2213 5.54256 87.2753 6.01081 85.9592C6.02382 85.9235 6.04804 85.893 6.07985 85.8723C6.11165 85.8515 6.14933 85.8416 6.18722 85.8441C6.22511 85.8466 6.26117 85.8613 6.28999 85.886C6.31882 85.9108 6.33884 85.9442 6.34708 85.9812C6.6673 87.4479 7.73529 88.3354 9.35195 88.7975C9.52598 88.8477 9.52097 89.0949 9.34444 89.1351C7.7847 89.4876 6.84058 90.5102 6.42357 92.1066C6.37818 92.2801 6.13068 92.2846 6.08604 92.1118Z" fill="#FF99B1" />
                            <path d="M79.6507 43.5859H19.3906V79.5988H79.6507V43.5859Z" fill="#1A1928" />
                            <path d="M19.3906 43.5835L46.2329 17.7199C47.1132 16.8721 48.2878 16.3984 49.51 16.3984C50.7322 16.3984 51.9068 16.8721 52.7871 17.7199L79.6514 43.5835H19.3906Z" fill="#3F3D56" />
                            <path d="M72.6911 32.4531H26.3523C24.7026 32.4531 23.3652 33.7905 23.3652 35.4402V61.8991C23.3652 63.5488 24.7026 64.8861 26.3523 64.8861H72.6911C74.3408 64.8861 75.6781 63.5488 75.6781 61.8991V35.4402C75.6781 33.7905 74.3408 32.4531 72.6911 32.4531Z" fill="white" />
                            <path d="M57.6042 34.582H27.5723V37.632H57.6042V34.582Z" fill="#DBDBDB" />
                            <path d="M70.8562 42.0234H27.5723V44.0719H70.8562V42.0234Z" fill="#DBDBDB" />
                            <path d="M70.8562 45.8867H27.5723V47.9352H70.8562V45.8867Z" fill="#DBDBDB" />
                            <path d="M70.8562 49.75H27.5723V51.7985H70.8562V49.75Z" fill="#DBDBDB" />
                            <path d="M73.2124 39.624C77.1957 39.624 80.4248 36.3949 80.4248 32.4116C80.4248 28.4283 77.1957 25.1992 73.2124 25.1992C69.2291 25.1992 66 28.4283 66 32.4116C66 36.3949 69.2291 39.624 73.2124 39.624Z" fill="#39BD81" />
                            <path d="M70.3553 34.6828L68.6895 31.0934L69.8028 30.5766L70.9518 33.0529L77.2194 30.1445L77.7359 31.2579L70.3553 34.6828Z" fill="white" />
                            <path d="M19.3906 43.5859L49.5086 61.5803L19.3906 79.5988V43.5859Z" fill="#4B4C61" />
                            <path d="M79.6516 43.5859L49.5098 61.5803L79.6516 79.5988V43.5859Z" fill="#4B4C61" />
                            <path d="M19.3906 79.5962L49.5086 55.9336L79.6504 79.5962H19.3906Z" fill="#585870" />
                            <path d="M69.2391 81.2836C68.8399 79.7354 67.8145 78.7115 66.0544 78.3035C65.8849 78.2641 65.8759 78.0277 66.0396 77.969C67.655 77.3932 68.6969 76.4473 69.1651 75.1311C69.1781 75.0954 69.2023 75.0649 69.2341 75.0442C69.2659 75.0234 69.3035 75.0135 69.3414 75.016C69.3793 75.0185 69.4154 75.0331 69.4442 75.0578C69.4731 75.0825 69.4931 75.1159 69.5014 75.1529C69.8216 76.6197 70.8896 77.5071 72.5062 77.9695C72.6803 78.0196 72.6753 78.2669 72.4987 78.3068C70.939 78.6596 69.9949 79.6822 69.5779 81.2783C69.5312 81.4521 69.2837 81.4576 69.2391 81.2836Z" fill="#96D7FF" />
                        </g>
                        <defs>
                            <clipPath id="clip0_780_30058">
                                <rect width="100" height="100.357" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>

                <div className="thank-you-footer-content">
                    <h2>Thank you for contacting us</h2>
                    <p>Your complaint has been successfully registered. We apologize for the inconvenience
                        caused and shall get back to you within 7 working days. </p>
                </div>

                <div className="shop-more">
                    <a href='https://www.beatxp.com/'>
                        <button>Shop more products</button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default ThankYou;